<template>
  <v-tabs-items v-model="focusTab">
    <v-tab-item
      v-for="(layer, layerIndex) in layers"
      :key="layerIndex"
      :value="layer.key"
    >
      <div
        class="px-4 pt-8 pb-4"
        :layer-blocks="layer.blocks"
        :form-key="formKey"
        v-if="createLayer(layer)"
      >
        <formBlocksContainer
          :layer-key="layer.key"
          :form-key="formKey"
          :layer-blocks="layer.blocks"
        ></formBlocksContainer>
      </div>
    </v-tab-item>
  </v-tabs-items>
</template>

<script>
export default {
  props: {
    formKey: String,
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    focusTab: null,
  }),
  methods: {
    createLayer(layer) {
      if(typeof layer.create != 'function') return true
      return layer.create(this.formInfo)
    },
  },
  computed: {
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    layers() {
      if(!Array.isArray(this.formConfig.layers)) return []
      return this.formConfig.layers
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.focusTab = this.value
      },
    },
    focusTab: {
      handler() {
        this.$emit('input', this.focusTab)
      },
    },
  },
  components: {
    formBlocksContainer: () => import('@/components/form/formBlocksContainer.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
